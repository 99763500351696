/*--------------------------
    Service Details Styles 
-----------------------------*/

.service-details-inner {
  .page-title {
    padding-left: 105px;
    h2 {
      &.title {
        font-size: 40px;
        line-height: 51px;
        margin-bottom: 14px;
      }
    }
    p {
      font-size: 24px;
      line-height: 30px;
      color: rgba(29, 29, 36, 0.75);
      margin-bottom: 0;
      text-align: justify;
    }
  }
  .sercice-details-content {
    .thumb {
      .video-popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0 none;
      }
    }
    .details {
      p {
        color: rgba(29, 29, 36, 0.75);
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 0;
        text-align: justify;
        & + p {
          margin-top: 30px;
        }
      }
      h5 {
        &.title {
          font-size: 28px;
          line-height: 30px;
          font-weight: 500;
          margin-top: 40px;
          margin-bottom: 27px;
          @media #{$sm-layout} {
            font-size: 18px;
            line-height: 38px;
            margin-top: 26px;
            margin-bottom: 18px;
          }
        }
      }
      ul {
        &.liststyle {
          li {
            &.subliststyle {
              font-size: 16px;
              color: rgba(51, 51, 63, 0.75);
            }
            font-size: 18px;
            line-height: 30px;
            color: rgba(29, 29, 36, 0.75);
            position: relative;
            padding-left: 30px;
            margin-top: 30px;
            &::before {
              position: absolute;
              content: "";
              width: 6px;
              height: 6px;
              border-radius: 100%;
              background: rgba(29, 29, 36, 0.75);
              left: 0;
              top: 10px;
            }
            & + li {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
